@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&family=Quattrocento+Sans:wght@400;700&family=Reem+Kufi:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Poppins:wght@400;500;600&display=swap');
/* font-family: 'Hedvig Letters Serif', serif;
font-family: 'Poppins', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
/* font-family: 'Lora', serif; */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
/* font-family: 'Open Sans', sans-serif; */


:root{
  --background-primary:#FBF7F4;
  --background-secondary:#FBF7F4;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  font-size: 62.5%;
}

body{
  background-color: var(--background-primary);
  font-size: 1.6rem;
  /* font-family: 'Lato', sans-serif; */
  font-family: 'Hedvig Letters Serif', serif;
  
  line-height: 1.7;
}
.navbar{
  display: none;
  background-color: var(--background-primary) !important;
}
.container--main{
  margin-left:26rem;
  margin-top: 4rem;
  margin-right: 3rem;
  background-color: #fff;
  padding: 2rem 4rem;
  min-height: 100vh;
}

.page-title{
  /* font-family: 'Oswald', sans-serif; */
  font-family: 'Hedvig Letters Serif', serif;
  text-transform: uppercase;
  font-size: 3.6rem;
  margin-bottom: 1.5rem;
}

.main-title{
  /* font-family: 'Oswald', sans-serif; */
  font-family: 'Hedvig Letters Serif', serif;
  font-size: 2.8rem;
  font-weight: 600;
}

strong{
  /* font-family: "Oswald",sans-serif; */
  font-family: 'Hedvig Letters Serif', serif;
 }

.sub-heading{
  /* font-family: 'Oswald', sans-serif; */
  font-family: 'Hedvig Letters Serif', serif;
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.manifesto-points{
  /* font-family: 'Quattrocento Sans', sans-serif; */
  font-family: 'Open Sans', sans-serif;
  list-style: none;
  font-size: 2rem;
  font-weight: 600;
}
.indicators-container{
  display: flex;
  font-style: "Reem Kufi",sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  gap: 1rem;
  padding: .5rem;
  flex-wrap: wrap;
  background-color: #FBF7F4;
  margin-bottom: 1rem;
}
.green{
  color: #2BC48A;
  font-size: 2rem;
}
.blue{
  color: #0073c2;
  font-size: 2rem;
}
.yellow{
  color: #FFCC66;
  font-size: 2rem;
}
.red{
  color: #FF470C;
  font-size: 2rem;
}
.piechart{
  width: 200px;
}
.Main-text{
  /* font-family: "Quattrocento Sans", sans-serif; */
  font-family: 'Open Sans', sans-serif;
  list-style: none;
  font-size: 2rem;
  font-weight: 500; 
}
p{
  font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 780px) {
  .container--main{
    margin-left:0rem;
    margin-right: 0;
    margin-top: 6rem;
  }
  .navbar{
    display: flex;
  }
}

@media only screen and (max-width: 500px) {
  .container--main{
    padding: 2rem;
    width: 100%;
  }
  .image-chart{
    width: 100%;
    height: auto;
  }
  
}
.accordian--main{
  cursor: pointer;
}
.accordian--reply{
 display: none;
  padding: 1rem;
  padding-left: 3rem;
  background-color: var(--background-primary);
  font-weight: 400;
  font-family: 'Lato',sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: .3rem;
  transition: all .5s;
}
.points-flex{
  display: flex;
  gap: .7rem;
}
.points-flex .uil{
  padding-top: .2rem;
  font-size: 2.2rem;
}