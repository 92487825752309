.TitleBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.date{
    font-family: "Quattrocento Sans", sans-serif;
  font-size: 2rem;
  font-weight: 600;
}
@media only screen and (max-width: 500px) {
   .TitleBox{
    flex-direction: column;
    align-items: flex-start;
   }
    
  }