:root{
    --background-primary:#FBF7F4;
    --background-secondary:#FFF;
    --active-color:#EEE6E2;
}
  

.sidebar{
    height: 100vh;
    width: 25rem;
    position: fixed;
    top: 0;
    left: 0;
    padding: 3rem;

}

.header__logo-box{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 3rem 0;
}

.header__logo-box h1{
    text-transform: uppercase;
    font-family: 'Reem Kufi', sans-serif;
}

.header__logo-box img{
    height: 7.5rem;
}

.navigation-menu{
    margin: auto;
    list-style: none;
}


.navigation-links{
    list-style: none;
    font-size: 1.6rem;
    padding: 1rem 1rem;
    display: block;
    font-weight: 700;
    color: black;
    text-decoration: none;
}
.navigation-links:hover{
    color: black;
}
.active{
    background-color: var(--active-color);
    border-radius: .2rem;
}
@media only screen and (max-width: 780px) {
    .sidebar{
        display: none;
    }
  }